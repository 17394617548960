.body {
    background-color: #282c34
}

html {
    background-image: url(https://cdn.pandahut.net/images/pandahut-youtube-channel-illustration-2560x1440-low1.jpg)!important;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: 50%
}

body {
    background: rgba(0,0,0,.63)!important;
    margin: 0;
    min-height: 100vh
}

.header {
    cursor: pointer;
    background-color: #0089cc;
    color: #fff!important;
    font-family: verdana!important
}

.content,.header {
    padding: 15px;
    margin-top: 4px
}

.content {
    font-family: verdana;
    font-size: 14px
}

header.MuiPaper-root {
    border: 1px solid #000;
    background: rgb(0 0 0/72%)!important;
    margin-bottom: 24px
}

.MuiAppBar-colorPrimary,.MuiCard-root {
    background-color: rgba(0,47,70,.9098039215686274)!important
}

.Answer {
    color: #fff
}

.Answer>p {
    margin-top: 4px
}

.CenterText {
    color: #fff;
    text-align: center;
    font-size: x-large;
    font-family: Verdana!important;
    margin: 8px auto 24px
}

.Category {
    color: #fff
}

.Question {
    text-decoration: underline
}

.Question>.header {
    display: list-item;
    list-style: circle;
    list-style-position: inside;
    text-transform: capitalize;
}

.Category>.header {
    display: list-item;
    list-style: inside;
    list-style-position: inside
}

.MainCard,.MostPopular {
    width: 80%;
    margin: 2rem auto;
    white-space: pre-line
}

.MostPopular>div>div>span>ul {
    -webkit-padding-start: 0!important;
    padding-inline-start:0!important}

.MostPopular {
    display: flex;
    color: #fff;
    padding: 5px;
    flex: 1 1;
    flex-direction: row
}

.MuiCardHeader-root {
    background: #0089cc;
    border-bottom: 2px solid #fff;
    display: flex;
    padding: 16px;
    align-items: center
}

.MuiCardContent-root>p,.MuiCardContent-root>span {
    color: #fff!important;
    font-family: system-ui;
    font-size: 18px
}

.MuiCardContent-root>span>ul>li {
    list-style: inside
}

.MuiCardContent-root>.CenterText {
    font-size: 1.5rem
}

.MostPopular .AnswerDetails>.MuiCard-root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    place-content: space-evenly
}

.MuiCardHeader-content>span {
    color: #fff;
    font-family: Verdana
}

.MostPopularCard {
    margin: 1rem auto;
    color: #fff;
    width: 30%;
    text-align: center;
    border: 2px solid rgba(0,171,255,.050980392156862744)!important
}

a,a:active,a:focus,a:hover,a:visited {
    text-decoration: underline;
    color: #81c4f8
}

.FocusOnAnswer {
    color: #fff;
    position: absolute;
    right: 10%;
    top: 105px
}

.AnswerDetails {
    z-index: 9999;
    margin: 2rem auto;
    white-space: pre-line;
    color: #fff!important;
    font-family: Verdana!important;
    padding: 4px;
    flex: 1 1;
    width: 80%
}

.Blur {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    z-index: -1;
    position: relative;
    top: 0
}

.MaxSizeImage {
    height: 32px;
    width: 32px;
    margin-top: 4px;
    margin-right: 3px;
    float: right
}

.MaxSizeImage:active,.MaxSizeImage:hover {
    background: rgba(117,206,255,.141)
}

.MuiTypography-body1 {
    text-align: center
}

.MuiTypography-h5 {
    font-size: 1.5rem!important;
    line-height: 1.334;
    letter-spacing: 0
}

.MuiTypography-body1,.MuiTypography-h5 {
    font-family: roboto,helvetica,arial,sans-serif;
    font-weight: 400
}

.MuiTypography-body1 {
    font-size: 2rem!important;
    line-height: 1.5;
    letter-spacing: .00938em
}

.MuiPaper-root {
    color: rgba(0,0,0,.87);
    transition: box-shadow .3s cubic-bezier(.4,0,.2,1) 0ms;
    border: 2px solid rgba(0,171,255,.050980392156862744)
}

@media(max-width: 800px) {
    .MostPopular {
        flex-direction:column!important;
        margin: 0 auto!important
    }

    .MainCard,.MostPopular,.MostPopularCard {
        width: 100%!important
    }

    .MainCard {
        margin: 0 auto!important
    }
}

/*# sourceMappingURL=main.31fe05dc.chunk.css.map */
